import { merge } from '@ecp/utils/common';

import { env as baseEnv } from './env';
import type { EnvironmentShared } from './types';

/**
 * Shared across all applications in local dev runtime environment.
 * Extends base env. Can override some values.
 */
export const env: EnvironmentShared = merge<
  EnvironmentShared,
  EnvironmentShared,
  EnvironmentShared
>({}, baseEnv, {
  static: {
    nodeEnv: 'development',
  },
  runtimeEnv: 'dev',
  vehicleImageApiKey: 'daefd14b-9f2b-4968-9e4d-9d4bb4af01d1',
  configApiRoot: 'https://configservice.partner-qa.homesitep2.com/v1/config-service/config',
  optimizely: {
    proxyEnabled: false,
  },
});
