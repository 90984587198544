import { merge } from '@ecp/utils/common';

import { env as baseEnv } from '../shared/env.dev';
import { env as baseEnvServicing } from './env';
import type { EnvironmentServicingShared } from './types';

/** DON'T USE IN ANY LIBRARY MODULES. TO BE IMPORTED IN APP ENV MODULES ONLY. */
export const env: EnvironmentServicingShared = merge<
  EnvironmentServicingShared,
  EnvironmentServicingShared,
  EnvironmentServicingShared,
  EnvironmentServicingShared
>({}, baseEnv, baseEnvServicing, {
  afeApiKey: 'qyql3G2iLXwQQQj1Vln9moE7WUVEAy2oCS0pPApwJ89ztcMI', // qa api key
  // afeApiKey: 'RKzW9hz4yBQpw8rgDhG4f0G0yw97WUnN', // dev api key
  creditCardTokenServiceUrl: 'https://qa-cctokengenerator.homesitep2.com/v1/creditcard/token',
  legacyPolicyServiceUrl: 'https://quickservice-dev.connectbyamfam.com/polaris',
  servicingApiRoot: 'https://api-qa.af1platform.com', // qa api root
  // servicingApiRoot: 'https://api-dev.af1platform.com', // dev api root
  servicingClientId: '0oa4jr77ssh3hJMLe1d7',
  azureClientId: '65558a15-c4ee-4afd-8b0f-58fa9f47d470',
  agentToolingApiRoot: 'https://apigee-dev.gcp.ent.corp/ent-agent-tooling/v1', // dev api root
  agentToolingAfeApiKey: 'WKeIhIQgJvY6ViOQgdTCFuZFYT1DAaM46gmzbaEmjxrzh0Ai', // dev api key
  // agentToolingApiRoot: 'https://qa.apigee-preprod.gcp.ent.corp/ent-agent-tooling/v1', // qa api root
  // agentToolingAfeApiKey: 'pTIuw6pATdU7HVQbsVLTzAi7KyOCElKSW4mpMlmN9mGYgMXo', // qa api key
  ecpDalAnalyticsEnabled: true,
  ecpDalRoot: 'https://dal.ecp-qa.homesitep2.com/v1',
  mockapi: {
    addDriver: true,
    addVehicle: true,
    additionalInterest: true,
    agentSupport: true,
    billing: true,
    claims: true,
    document: true,
    membership: true,
    optimizely: true,
    payment: true,
    policy: true,
    preferences: true,
    sapiAnalytics: true,
    users: true,
    userAuthn: true,
  },
});
